import PageHeader from '@/components/PageHeader'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
import root from '@/minxin/root'
const Index = {
  name: 'purchaseMaterials',
  mixins: [tableMixin, root],
  components: {
    PageHeader,
    Adsearch
  },
  data() {
    return {
      root_name: 'cgwlqd',
      root_msg: '采购物料清单',
      visible: false,
      isShow: false,
      formSearch: {
        MaterialTypeName: '', // 物料类型
        CreateUserName: '', // 创建人名称
        OrderSapID: '', // 采购单号
        // ReportType: 1, // 1： 按订单 2：按交货单
        OrderID: '',
        SendCompanyName: '', //  终端名称
        BuyCompanyName: '',
        SellCompanyName: '', // "供应商名称"
        InvName: '',
        InvCode: '', // "物料号"
        ManuPartNum: '', // "原厂编号"
        CreateStartTime: '',
        CreateEndTime: '',
        CreateUserId: '',
        WriteTime: [],
        DeliveryID: '',
        OrderType: 2, // 订单类型 1 ：销售订单 2 ：采购订单
        BrandName: '', // "品牌"
        IsOver: 2, // 1: 已清 2 ：未清
        KeyWords: '',
        ReceiveNum: '',
        PageIndex: 1,
        PageSize: 10
      },
      adSearchForm: [
        { name: '采购单号', value: 'OrderID' },
        { name: '供应商', value: 'SellCompanyName' },
        { name: '送达方', value: 'SendCompanyName' },
        { name: '物料编号', value: 'InvCode' },
        { name: '原厂编号', value: 'ManuPartNum' },
        { name: '品牌', value: 'BrandName' },
        { name: '物料类型', value: 'MaterialTypeName' },
        { name: '创建人', value: 'CreateUserName' },
        { name: '创建时间', value: 'WriteTime', date: true }
      ],
      selects: []
    }
  },
  watch: {
    selects(a) {
      this.formSearch.SelectIDList = []
      if (a.length <= 0) return this.formSearch.SelectIDList = []
      a.map(item => {
        this.formSearch.SelectIDList.push(item.IDX)
      })
    }
  },
  methods: {
    selectAll(e) {
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    search() {
      this.isShow = true
    },
    searchAd() {
      if (this.formSearch.WriteTime.length > 0) {
        this.formSearch.CreateStartTime = this.formSearch.WriteTime[0]
        this.formSearch.CreateEndTime = this.formSearch.WriteTime[1]
      }
      console.log(this.formSearch)
      this.setKeysData(this.formSearch, this.adSearchForm) // 展示搜索条件
      this.getTableDataAsync()
      this.currentPage = 1
      this.isShow = false
    },
    selectType(num) {
      this.formSearch.IsOver = num
      this.getTableDataAsync()
    },
    exportS() {
      this.$refs.ExportLoading.open()
      //  ExportPurchaseInfoNotFinsh  ExportGetOrderInfoNotFinshList
      this.$api.ExportPurchaseInfoNotFinsh(this.formSearch).then(res => {
        if (!res.Message) return this.$message.error('错误：返回数据为空')
        if (res.RetCode === '0') {
          const name = this.$minCommon.getExportName('采购单查询')
          this.$refs.ExportLoading.success(res.Message, name)
          return this.$minCommon.downloadFile(res.Message, name)
        }
        return this.$message.error('错误：', res.Msg)
      })
    },
    // 复制到页面上实现获取数据逻辑
    // 按物料
    async getTableDataAsync(page, pageSize) {
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize
      // GetOrderInfoNotFinshList
      const response = await this.$api.GetPurchaseInfoNotFinshList(this.formSearch)
      this.tableData = []
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      return response
    }
  }
}
export default Index
