<template>
  <div class="purchaseMaterials">
    <PageHeader title="采购物料清单" />
    <div class="searchView flex a-center m-tb-10 j-between p-lr-20">
      <div class="flex m-left-10 SearchBox_30  m-right-20" style="flex: 1">
        <el-input
          v-model="formSearch.KeyWords"
          size="medium"
          placeholder="可通过订单编号、物料号、物料名称、终端/买方、 供应商名称"
          class="rule-input-edit"
        >
          <template slot="prepend">
            <div class="pointer" @click="search">
              <i class="el-icon-s-operation" />
              <span>高级搜索</span>
            </div>
          </template>
          <div
            slot="append"
            @click="searchGetTableDataAsync"
          >
            搜 索
          </div>
        </el-input>
      </div>
      <div class="flex">
        <el-popover
          class="OrderDropDown"
          placement="bottom"
          trigger="hover"
          :width="80"
          popper-class="test_pop_view"
        >
          <div class="flex column">
            <div class="cursor" @click="selectType(1)">已发清</div>
            <div class="p-tb-10 cursor" @click="selectType(2)">未发清</div>
          </div>
          <div slot="reference" class="out white bg p-lr-10 p-tb-5 min-border-right flex a-center f12 m-right-10 pointer" @click="visible = !visible">
            <div>{{ formSearch.IsOver === 1 ? '已发清' : '未发清' }}</div>
            <i class="el-icon-caret-bottom" />
          </div>
        </el-popover>
      </div>
      <div
        slot="reference"
        class="out p-lr-10 min-border flex a-center m-right-10 pointer ModelBttton-white-30"
        @click="exportS "
      >
        <i class="el-icon-upload2 f15 p-right-10" /> 导出
      </div>
    </div>
    <SearchKeys v-if="isProductCjq" class="m-lr-20" :search-keys="searchKeys" @close="closeTag($event,formSearch,adSearchForm,'WriteTime','CreateStartTime','CreateEndTime')" />

    <div class="p-lr-20">
      <!-- 按订单查询 -->
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :header-cell-style="{ background: 'rgb(247,248,252)' }"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        height="calc(100vh - 330px)"
        row-key="id"
        border
        :fit="true"
        @select-all="selectAll"
        @select="select"
      >
        <el-table-column fixed="left" type="selection" width="40" />
        <el-table-column prop="OrderPurchaseSapID" label="采购单号" show-overflow-tooltip />
        <el-table-column prop="SellCompanyName" label="供应商" show-overflow-tooltip />
        <el-table-column prop="SendCompanyName" label="送达方" show-overflow-tooltip />
        <el-table-column prop="InvCode" label="物料编号" show-overflow-tooltip />
        <el-table-column prop="ManuPartNum" label="原厂编号" show-overflow-tooltip />
        <el-table-column prop="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column prop="BrandName" label="品牌" show-overflow-tooltip />
        <el-table-column prop="MaterialTypeName" label="物料类型" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.MaterialTypeName + scope.row.MaterialSubTypeName }}
          </template>
        </el-table-column>
        <el-table-column prop="Specification" label="规格" show-overflow-tooltip />
        <el-table-column prop="StockUnitN" label="单位" show-overflow-tooltip />
        <el-table-column prop="Num" label="采购数量" show-overflow-tooltip />
        <el-table-column prop="PutinNum" label="已入库数量" show-overflow-tooltip />
        <el-table-column prop="NumOfNotFinish" label="未清数量" show-overflow-tooltip />
        <el-table-column prop="CreateUserName" label="创建人" show-overflow-tooltip />
        <el-table-column prop="WriteTime" label="创建时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.WriteTime), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <Adsearch
      :show="isShow"
      :form="formSearch"
      :ad-search-form="adSearchForm"
      @cancel="(e) => (isShow = e)"
      @searchAd="searchAd"
    />
    <!-- 导出 -->
    <ExportLoading ref="ExportLoading" />
  </div>
</template>
<script>
import Index from '@/minxin/purchasing-management/purchaseMaterials'
export default Index
</script>
<style scoped lang="scss">
@import "@/style/search.scss";
</style>
